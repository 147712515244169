var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.currentUser && 
    ( _vm.currentUser.user_role_name == 'SUPER_ADMIN' ||
      _vm.currentUser.user_role_name == 'ADMIN'
      )
  )?_c('hello-world'):(
    _vm.currentUser && 
    ( _vm.currentUser.user_role_name == 'FRANCHISE' ||
      _vm.currentUser.user_role_name == 'FRANCHISEE'
    )
  )?_c('FranchiseHome'):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }