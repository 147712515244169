<template>
  <hello-world
    v-if="
      currentUser && 
      ( currentUser.user_role_name == 'SUPER_ADMIN' ||
        currentUser.user_role_name == 'ADMIN'
        )
    "
  />

  <FranchiseHome
    v-else-if="
      currentUser && 
      ( currentUser.user_role_name == 'FRANCHISE' ||
        currentUser.user_role_name == 'FRANCHISEE'
      )
    "
  />
</template>

<script>
import HelloWorld from "../components/HelloWorld";
import FranchiseHome from "../components/franchise/FranchiseHome.vue";

export default {
  name: "Home",

  components: {
    HelloWorld,
    FranchiseHome,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
