<template>
  <div>
    <div class="text-center">
      <img src="@/assets/images/temp-banner.png" />
    </div>

    <div class="space-50"></div>

    <div class="ml-10" v-if="isUserFranchisee">
      <router-link to="franchisee/customise-insurance/details" class="no-link">
        <v-btn color="#FFC145">
          <v-icon class="mr-2" small>mdi-shield-account</v-icon>
          Get Insured Now</v-btn
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FranchiseHome",

  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserFranchisee() {
      return this.userRole?.includes("FRANCHISEE");
    },
  },
};
</script>

<style lang="scss" scoped></style>
